import React, { Component } from "react";
import { Link } from 'react-router-dom';
import sanityClient from '../../client';
import Spinner from '../../elements/common/Spinner'

class PortfolioList extends Component{

    constructor(props) {
        super(props);
        this.state = {
            projectList: []
        }
    }

    async componentDidMount() {

        const query = `*[_type == "project"] {
            title,
            slug,
            excerpt,
            type,
            thumbnail {
                asset->{
                    _id,
                    url
                }
            }
        }`
        try {
            const projectData = await sanityClient.fetch(query);
            this.setState({projectList: projectData.slice(0, this.props.item)})
        }
        catch(err) {
            console.error(err)
        }
        
    }

    render(){

        if(this.state.projectList === null || !this.state.projectList.length > 0) return <Spinner />

        const {column , styevariation } = this.props;

        return(
            <React.Fragment> 
                {this.state.projectList && this.state.projectList.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`im_portfolio ${styevariation}`}>
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    <Link to={`/portfolio/${value.slug.current}`}>
                                        <img src={value.thumbnail.asset.url} alt={value.thumbnail.alt} />
                                    </Link>
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                            <Link to={`/portfolio/${value.slug.current}`}>{value.type.toUpperCase()}</Link>
                                        </div>
                                        <h4 className="title"><Link to={`/portfolio/${value.slug.current}`}>{value.title}</Link></h4>
                                    </div>
                                    <div className="portfolio_hover">
                                        <p>{value.excerpt}</p>
                                    </div>
                                </div>
                            </div>
                            <Link className="transparent_link" to={`/portfolio/${value.slug.current}`}></Link>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;