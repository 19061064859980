import React from 'react';
import {FaTwitter ,FaFacebookF , FaLinkedinIn, FaGithub} from "react-icons/fa";
import { Link } from 'react-router-dom'

const SocialShare = [
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/in/kristijan-kre%C5%A1i%C4%87-2b1176154'},
    {Social: <FaGithub /> , link: 'https://github.com/kristijan-kresic-hvar'},
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/kristijan.kresic.3'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/kk_webdev'},
]

const FooterTwo = () => {
    return (
        <div className="footer-style-2 ptb--30 bg_color--6">
            <div className="wrapper plr--50 plr_sm--20">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner">
                            <div className="logo text-center text-sm-left mb_sm--20">
                                <Link to="/">
                                    <img src="/assets/images/logo/logo_text.svg" alt="Logo images"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner text-center">
                            <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                {SocialShare.map((val , i) => (
                                    <li key={i}><a target="_blank" rel="noopener noreferrer" href={`${val.link}`}>{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                            <div className="text">
                                <p>Copyright © {new Date().getFullYear()} Kristijan Krešić. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterTwo;