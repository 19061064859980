import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component {
    render() {
        let
            tab1 = "Experience",
            tab2 = "Education",
            tab3 = 'Tools';

        const { tabStyle } = this.props
        return (
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>


                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <p><a target="_blank" rel="noopener noreferrer" href="https://amondi-media.com/">amondi Media d.o.o.</a> <span>- Frontend React Developer</span></p> Apr 2022 - Nov 2022
                                                </li>
                                                <li>
                                                    <p><a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Freelancer">Freelancer</a> <span>- Web Developer</span></p> 2018 - present
                                                </li>
                                                <li>
                                                    <p><a target="_blank" rel="noopener noreferrer" href="https://reroot.agency/">Reroot.agency</a> <span>- Web Developer</span></p> 2020 - 2021 / Contract
                                                </li>
                                                <li>
                                                    <p><a target="_blank" rel="noopener noreferrer" href="https://robinson-hvar.com/">Robinson Restaurant <br />Mekićevica</a> <span>- Wordpress Developer</span></p> 2019 - 2021
                                                </li>
                                                <li>
                                                    <p><span style={{ fontWeight: 'bold', color: 'rgba(198,201,216,0.75)' }}>Tabacco &amp; Gift<br /> Shop </span><span>- Wordpress Developer</span></p> 2018 - 2020
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>



                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <p><a target="_blank" rel="noopener noreferrer" href="https://www.theodinproject.com/">Full Curriculum</a> <span>-<br /> theodinproject.com</span></p> 2018 - 2020
                                                </li>
                                                <li>
                                                    <p><a target="_blank" rel="noopener noreferrer" href="https://www.freecodecamp.org/">Web Development Essentials</a> <span>-<br /> Freecodecamp.org </span></p> 2018 - 2019
                                                </li>
                                                <li>
                                                    <p><a target="_blank" rel="noopener noreferrer" href="https://www.algebra.hr/">PHP Developer </a> <span>-<br /> Otvoreno Učilište Algebra</span></p> 2017 - 2018
                                                </li>
                                                <li>
                                                    <p><a target="_blank" rel="noopener noreferrer" href="https://www.algebra.hr/">Web Designer &amp; <br />Developer</a> <span>-<br /> Otvoreno Učilište Algebra</span></p> 2016 - 2017
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <p>Javascript / TypeScript / React.js</p>
                                                </li>
                                                <li>
                                                    <p>CSS / Tailwind / Sass</p>
                                                </li>
                                                <li>
                                                    <p>Git / Github</p>
                                                </li>
                                                <li>
                                                    <p>Terminal / NPM / Yarn</p>
                                                </li>
                                                <li>
                                                    <p>Wordpress</p>
                                                </li>
                                                <li>
                                                    <p>Sanity.io / Firebase</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;