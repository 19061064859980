import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import { FiCheck } from "react-icons/fi";
import Spinner from '../elements/common/Spinner';

import sanityClient from '../client';

class PortfolioDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            project: null,
            slug: this.props.match.params.slug
        }
    }

    async componentDidMount() {

        const query = `*[slug.current == "${this.state.slug}"]{
            title,
            _id,
            slug,
            excerpt,
            about,
            solution,
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            gallery[]{
                asset->{
                    _id,
                    url
                }
            },
            hostedUrl,
            codeUrl,
            branch,
            client,
            about,
            aboutClient,
            technologies,
            type,
        }`
        try {
            const projectData = await sanityClient.fetch(query)

            // if there is no project data, redirect to error 404
            if (!projectData.length > 0) return this.props.history.push('/404')

            // save the state
            this.setState({ project: projectData[0] })
        }
        catch (e) {
            console.error(e)
        }
    }

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle={`${this.state.project ? this.state.project.title + " | Kristijan Krešić" : "Kristijan Krešić"}`} />

                <div className="active-dark bg_color--9">
                    <HeaderThree hidenav={true} homeLink="/" logo="symbol-dark" headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                </div>

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 pb_md--100 pb_sm--100 bg_image" style={{ background: `url(${this.state.project && this.state.project.mainImage.asset.url}) no-repeat fixed top/cover` }} data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    {/* <h2 className="title theme-gradient">Getting tickets to the big show wow...</h2> */}
                                    <h2 className="title theme-gradient">{this.state.project && this.state.project.title}</h2>
                                    {/* <p>Contrary to popular belief, Lorem Ipsum is not simply random text. </p> */}
                                    <p>{this.state.project && this.state.project.excerpt}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="row row--35 mb--50">
                                        <div className="col-lg-6">
                                            <div className="inner">
                                                <div className="section-title">
                                                    <h2 className="title">{this.state.project && this.state.project.title}</h2>
                                                    {this.state.project && this.state.project.about.map((block, index) =>
                                                        <p key={index} className="description mt--30">{block.children[0].text}</p>
                                                    )}
                                                </div>
                                                <div className="portfolio-view-list d-flex flex-wrap">
                                                    <div className="port-view">
                                                        <span>Branch</span>
                                                        <h4>{this.state.project && this.state.project.branch}</h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Project Type</span>
                                                        <h4 style={{ textTransform: 'capitalize' }}>{this.state.project && this.state.project.type}</h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Client</span>
                                                        <h4>{this.state.project && this.state.project.client}</h4>
                                                    </div>
                                                </div>

                                                {this.state.project && this.state.project.hostedUrl ? <div className="portfolio-details-btn mt--30">
                                                    <a target="_blank" rel="noopener noreferrer" className="btn-default btn-border" href={this.state.project && this.state.project.hostedUrl}>Launch The Project</a>
                                                </div> : ""}

                                                {this.state.project && this.state.project.codeUrl ? <div className="portfolio-details-btn mt--30">
                                                    <a target="_blank" rel="noopener noreferrer" className="btn-default btn-border btn-dark" href={this.state.project && this.state.project.codeUrl}>View The Code</a>
                                                </div> : ""}

                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="portfolio-details-accordion mt_md--40 mt_sm--40">
                                                <div className="inner">
                                                    <div className="section-title">
                                                        <h4 className="title">Solution</h4>
                                                        {this.state.project && this.state.project.solution.map((block, index) =>
                                                            <p key={index} className="description mt--30">{block.children[0].text}</p>
                                                        )}
                                                        <ul className="list-style--1">
                                                            {this.state.project && this.state.project.technologies.map((technology, index) => {
                                                                return <li key={index}><FiCheck /> {technology}</li>
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.project && this.state.project.gallery &&
                                        <div className="portfolio-thumb-inner">
                                            {this.state.project.gallery.map((image, index) =>
                                                <div key={index} className="thumb mb--30">
                                                    <img src={image.asset.url} alt="Portfolio Images" />
                                                </div>
                                            )}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />

                {this.state.project === null &&
                    <div style={{ position: 'fixed', top: '0', left: '0', right: '0', bottom: '0', backgroundColor: '#0E1525', zIndex: '1000', userSelect: 'none' }}>
                        <Spinner />
                    </div>
                }

            </React.Fragment>
        )
    }
}
export default PortfolioDetails;
