import React, { Component } from "react";
import { FaTwitter, FaFacebookF, FaLinkedinIn, FaGithub } from "react-icons/fa";
import { FiX, FiMenu } from "react-icons/fi";
import Scrollspy from 'react-scrollspy';
import { Link } from 'react-router-dom';

const SocialShare = [
    { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/in/kristijan-kre%C5%A1i%C4%87-2b1176154' },
    { Social: <FaGithub />, link: 'https://github.com/kristijan-kresic-hvar' },
    { Social: <FaFacebookF />, link: 'https://www.facebook.com/kristijan.kresic.3' },
    { Social: <FaTwitter />, link: 'https://twitter.com/kk_webdev' },
]

class HeaderThree extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    scrollToSection(e, section) {
        e.preventDefault();
        document.getElementById(section).scrollIntoView({ behavior: 'smooth' });
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    stickyHeader() { }
    render() {
        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            } else {
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });
        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const { color = 'default-color' } = this.props;
        const logoUrl = <img src="/assets/images/logo/logo.svg" alt="Digital Agency" />

        return (
            <header className={`header-area header-style-two header--fixed ${color}`}>
                <div className="header-wrapper">
                    <div className="header-left d-flex align-items-center">
                        <div className="logo py-2">
                            <Link to={this.props.homeLink}>
                                {logoUrl}
                            </Link>
                        </div>
                        {!this.props.hidenav && <nav className="mainmenunav d-lg-block ml--50">
                            <Scrollspy className="mainmenu" items={['home', 'about', 'service', 'portfolio', 'contact']} currentClassName="is-current" offset={-200}>
                                <li onClick={(e) => this.scrollToSection(e, 'home')}><a href="#home">Home</a></li>
                                <li onClick={(e) => this.scrollToSection(e, 'about')}><a href="#about">About</a></li>
                                <li onClick={(e) => this.scrollToSection(e, 'service')}><a href="#service">Services</a></li>
                                <li onClick={(e) => this.scrollToSection(e, 'portfolio')}><a href="#portfolio">Portfolio</a></li>
                                {/* <li><a href="#blog">Blog</a></li> */}
                                <li onClick={(e) => this.scrollToSection(e, 'contact')}><a href="#contact">Contact</a></li>
                            </Scrollspy>
                        </nav>}
                    </div>
                    <div className="header-right">
                        <div className="social-share-inner d-none d-sm-block">
                            <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                                {SocialShare.map((val, i) => (
                                    <li key={i}><a target="_blank" rel="noopener noreferrer" href={`${val.link}`}>{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                        <div className="header-btn">
                            <a download target="_blank" rel="noopener noreferrer" className="btn-default btn-border btn-opacity" href="/assets/cv/KK_CV.pdf">
                                <span>Download CV</span>
                            </a>
                        </div>
                        {!this.props.hidenav &&
                            <>
                                <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
                                    <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                                </div>

                                <div className="close-menu d-block d-lg-none">
                                    <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </header>
        )
    }
}
export default HeaderThree;