import React from 'react';
// import { Link } from 'react-router-dom';
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
// import Slider from "react-slick";
import PortfolioList from "../elements/portfolio/PortfolioList";
// import { slickDot , portfolioSlick2 } from "../page-demo/script";


const Portfolio = () => {
    return (
        <>
            <PageHelmet pageTitle='Portfolio | Kristijan Krešić' />

            {/* Start Header Area  */}
            <div className="active-dark bg_color--9">
                <HeaderThree hidenav={true} homeLink="/" logo="symbol-dark" headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            </div>
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Portfolio'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--5">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <span className="subtitle">All projects</span>
                                        <h2 className="title">All Works</h2>
                                        <p className="description">Check my github profile for more smaller projects not mentioned here.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-left mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start Portfolio Area */}
                {/* <div className="portfolio-area pt--90 pb--140 bg_color--1">
                    <div className="rn-slick-dot">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slick-space-gutter--15 slickdot--20">
                                        <Slider {...slickDot}>
                                            {list.map((value , index) => (
                                                <div className="single_im_portfolio" key={index}>
                                                    <div className="im_portfolio">
                                                        <div className="thumbnail_inner">
                                                            <div className="thumbnail">
                                                                <Link to="/portfolio-details">
                                                                    {value.image}
                                                                </Link>    
                                                            </div>
                                                        </div>
                                                        <div className="content">
                                                            <div className="inner">
                                                                <div className="portfolio_heading">
                                                                    <div className="category_list">
                                                                        <Link to="/portfolio-details">{value.category}</Link>
                                                                    </div>
                                                                    <h4 className="title"><Link to="/portfolio-details">{value.title}</Link></h4>
                                                                </div>
                                                                <div className="portfolio_hover">
                                                                    <p>{value.description}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Link className="transparent_link" to="/portfolio-details"></Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Portfolio Area */}
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <FooterTwo />
            {/* End Footer Area  */}
        </>
    )
}

export default Portfolio;