import React ,{ Component }from "react";
import { FiActivity, FiCode, FiTrendingUp } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCode />,
        title: 'Website Development',
        description: 'High attention to detail, optimal user experience and high scalability'
    },
    {
        icon: <FiActivity />,
        title: 'Website Support',
        description: 'I make sure that your website is working as it should, providing regular maintenances'
    },
    {
        icon: <FiTrendingUp />,
        title: 'SEO Optimization',
        description: 'Optimization of your site for best ranking in search engines and link building ( SEO )'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row service-main-wrapper">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <div className="service service__style--2 text-left bg-gray">
                                <div className="icon">
                                    {val.icon}
                                </div>
                                <div className="content">
                                    <h3 className="title">{val.title}</h3>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
