import React from 'react';

const Spinner = () => {
    return(
        <div className="mx-auto my-3 d-flex justify-content-center">
            <div className="spinner-border" role="status" style={{ width: '3rem', height: '3rem', color: 'var(--color-primary)' }}>
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export default Spinner